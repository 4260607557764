@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. Fonts
2. Body and some general stuff
3. Header
	3.1 Top Bar
	3.2 Header Content
	3.3 Logo
	3.4 Main Nav
	3.5 Hamburger
4. Menu
5. Home
6. About
7. Milestones
8. Teachers
9. Footer


******************************/

/***********
1. Fonts
***********/

@import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,600,700,800,900');

/*********************************
2. Body and some general stuff
*********************************/

*
{
	margin: 0;
	padding: 0;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
body
{
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	font-weight: 500;
	background: #FFFFFF;
	color: #6c6a74;
}
div
{
	display: block;
	position: relative;
	-webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
}
ul
{
	list-style: none;
	margin-bottom: 0px;
}
p
{
	font-family: 'Montserrat', sans-serif;
	font-size: 14px;
	line-height: 2.14;
	font-weight: 500;
	color: #6c6a74;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a
{
	display: inline;
	position: relative;
	color: inherit;
	border-bottom: solid 1px #ffa07f;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
p:last-of-type
{
	margin-bottom: 0;
}
a, a:hover, a:visited, a:active, a:link
{
	text-decoration: none;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
p a:active
{
	position: relative;
	color: #FF6347;
}
p a:hover
{
	color: #FFFFFF;
	background: #ffa07f;
}
p a:hover::after
{
	opacity: 0.2;
}
::selection
{
	background: #ff8a00;
	color: #FFFFFF;
}
p::selection
{
	
}
h1{font-size: 48px;}
h2{font-size: 36px;}
h3{font-size: 24px;}
h4{font-size: 18px;}
h5{font-size: 14px;}
h1, h2, h3, h4, h5, h6
{
	font-family: 'Montserrat', sans-serif;
	color: #44425a;
	-webkit-font-smoothing: antialiased;
	-webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
	text-shadow: rgba(0,0,0,.01) 0 0 1px;
}
h1::selection, 
h2::selection, 
h3::selection, 
h4::selection, 
h5::selection, 
h6::selection
{
	
}
.form-control
{
	color: #db5246;
}
section
{
	display: block;
	position: relative;
	box-sizing: border-box;
}
.clear
{
	clear: both;
}
.clearfix::before, .clearfix::after
{
	content: "";
	display: table;
}
.clearfix::after
{
	clear: both;
}
.clearfix
{
	zoom: 1;
}
.float_left
{
	float: left;
}
.float_right
{
	float: right;
}
.trans_200
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.trans_300
{
	-webkit-transition: all 300ms ease;
	-moz-transition: all 300ms ease;
	-ms-transition: all 300ms ease;
	-o-transition: all 300ms ease;
	transition: all 300ms ease;
}
.trans_400
{
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.trans_500
{
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.fill_height
{
	height: 100%;
}
.super_container
{
	width: 100%;
	overflow: hidden;
}
.prlx_parent
{
	overflow: hidden;
}
.prlx
{
	height: 130% !important;
}
.parallax-window
{
    min-height: 400px;
    background: transparent;
}
.parallax_background
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
}
.nopadding
{
	padding: 0px !important;
}
.button
{
	display: inline-block;
	width: auto;
	height: 47px;
	background: #ff8a00;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.button a
{
	display: block;
	position: relative;
	padding-left: 33px;
	padding-right: 77px;
	line-height: 47px;
	font-size: 12px;
	font-weight: 600;
	color: #FFFFFF;
	text-transform: uppercase;
	white-space: nowrap;
}
.button_arrow
{
	position: absolute;
	top: 0;
	right: 0;
	width: 44px;
	height: 100%;
	background: #ff6600;
	text-align: center;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.button_arrow i
{
	font-size: 20px;
	line-height: 47px;
	color: #ffae00;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.button:hover
{
	background: #ffae00;
}
.button:hover .button_arrow
{
	background: #ff8a00;
}
.button:hover .button_arrow i
{
	color: #ffae00;
}
.section_title h2
{
	font-weight: 600;
}
.section_subtitle
{
	font-size: 14px;
	color: #6c6a74;
	text-align: center;
	margin-top: 66px;
	line-height: 2.14;
}

/*********************************
3. Header
*********************************/

.header
{
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 100;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.05);
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.header.scrolled
{
	top: -61px;
}

/*********************************
3.1 Top Bar
*********************************/

.top_bar
{
	width: 100%;
	background: #2c2b31;
}
.header.scrolled .top_bar
{

}
.top_bar_container
{
	width: 100%;
	height: 100%;
}
.top_bar_content
{
	width: 100%;
	height: 61px;
}
.top_bar_contact_list li
{
	display: inline-block;
}
.question
{
	font-family: 'Montserrat', serif;
	font-size: 12px;
	font-weight: 600 !important;
	color: #918ea0;
	margin-left: 0px;
	text-transform: uppercase;
}
.top_bar_contact_list li > div
{
	display: inline-block;
	font-size: 12px;
	font-weight: 500;
	color: #918ea0;
}
.top_bar_contact_list li > div
{
	margin-left: 1px;
}
.top_bar_contact_list li:first-child
{
	margin-right: 42px !important;
}
.top_bar_contact_list li:not(:last-child)
{
	margin-right: 33px;
}
.top_bar_login
{
	
}
.top_bar_login ul li
{
	display: inline-block;
	position: relative;
}
.top_bar_login ul li a
{
	font-size: 12px;
	text-transform: uppercase;
	font-weight: 600;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.top_bar_login ul li a:hover
{
	color: #ff8a00;
}
.top_bar_login ul li:not(:last-child)::after
{
	display: inline-block;
	margin-left: 5px;
	margin-right: 1px;
	content: '/';
	font-size: 12px;
	color: #FFFFFF;
	font-weight: 600;
}

/*********************************
3.2 Header Content
*********************************/

.header_container
{
	width: 100%;
	background: #FFFFFF;
}
.header_content
{
	height: 90px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.header.scrolled .header_content
{
	height: 70px;
}

/*********************************
3.3 Logo
*********************************/

.logo,
.logo_text
{
	display: inline-block;
}
.logo
{
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.logo_text
{
	font-family: 'Montserrat', serif;
	font-size: 18px;
	font-weight: 700;
	line-height: 0.75;
	margin-left: 1px;
	color: #464646;
	text-transform: uppercase;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}

/*********************************
3.4 Main Nav
*********************************/

.main_nav_contaner
{

}
.main_nav,
.search_button
{
	display: inline-block;
}
.main_nav li
{
	display: inline-block;
	position: relative;
	height: 31px;
}
.main_nav li:not(:last-child)
{
	margin-right: 13px;
}
.main_nav li a
{
	display: block;
	font-size: 12px;
	font-weight: 600;
	line-height: 31px;
	background: #FFFFFF;
	color: #2c2b31;
	padding-left: 19px;
	padding-right: 19px;
	text-transform: uppercase;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.main_nav li a:hover,
.main_nav li.active a
{
	color: #FFFFFF;
	background: #ff8a00;
}
.main_nav li a:hover
{
	background: #ff8a00;
}
.search_button
{
	margin-left: 88px;
	cursor: pointer;
}
.search_button i
{
	font-size: 15px;
	color: #181818;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.search_button:hover i
{
	color: #ff8a00;
}
.header_search_form
{
	display: block;
	position: relative;
	width: 40%;
}
.header_search_container
{
	position: absolute;
	bottom: 0px;
	left: 0px;
	width: 100%;
	background: #2c2b31;
	z-index: -1;
	opacity: 0;
	-webkit-transition: all 400ms ease;
	-moz-transition: all 400ms ease;
	-ms-transition: all 400ms ease;
	-o-transition: all 400ms ease;
	transition: all 400ms ease;
}
.header_search_container.active
{
	bottom: -73px;
	opacity: 1;
}
.header_search_content
{
	width: 100%;
	height: 73px;
}
.search_input
{
	width: 100%;
	height: 40px;
	border: none;
	outline: none;
	padding-left: 20px;
	background: transparent;
	border-bottom: solid 2px #ff8a00;
	color: #FFFFFF;
}
.header_search_button
{
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	border: none;
	outline: none;
	cursor: pointer;
}

/*********************************
3.5 Hamburger
*********************************/

.hamburger_container
{

}
.hamburger
{
	display: none;
	cursor: pointer;
}
.hamburger i
{
	font-size: 20px;
	color: #353535;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.hamburger:hover i
{
	color: #ff8a00;
}

/*********************************
4. Menu
*********************************/

.menu
{
	position: fixed;
	top: 0;
	right: -400px;
	width: 400px;
	height: 100vh;
	background: #FFFFFF;
	z-index: 101;
	padding-right: 60px;
	padding-top: 87px;
	padding-left: 50px;
	box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
}
.menu .logo a
{
	color: #000000;
}
.menu.active
{
	right: 0;
}
.menu_close_container
{
	position: absolute;
	top: 30px;
	right: 60px;
	width: 18px;
	height: 18px;
	transform-origin: center center;
	-webkit-transform: rotate(45deg);
	-moz-transform: rotate(45deg);
	-ms-transform: rotate(45deg);
	-o-transform: rotate(45deg);
	transform: rotate(45deg);
	cursor: pointer;
}
.menu_close
{
	width: 100%;
	height: 100%;
	transform-style: preserve-3D;
}
.menu_close div
{
	width: 100%;
	height: 2px;
	background: #232323;
	top: 8px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_close div:last-of-type
{
	-webkit-transform: rotate(90deg) translateX(-2px);
	-moz-transform: rotate(90deg) translateX(-2px);
	-ms-transform: rotate(90deg) translateX(-2px);
	-o-transform: rotate(90deg) translateX(-2px);
	transform: rotate(90deg) translateX(-2px);
	transform-origin: center;
}
.menu_close:hover div
{
	background: #937c6f;
}
.menu .logo
{
	margin-bottom: 60px;
}
.menu_nav ul li
{
	margin-bottom: 9px;
}
.menu_nav ul li a
{
	font-size: 16px;
	color: #2c2b31;
	font-weight: 700;
	letter-spacing: 0.05em;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_nav ul li a:hover
{
	color: #ff6600;
}
.menu .search
{
	width: 100%;
	margin-bottom: 67px;
}
.search
{
	display: inline-block;
	width: 400px;
	-webkit-transform: translateY(2px);
	-moz-transform: translateY(2px);
	-ms-transform: translateY(2px);
	-o-transform: translateY(2px);
	transform: translateY(2px);
}
.menu .header_search_form
{
	width: 100%;
}
.search form
{
	position: relative;
}
.menu .search_input
{
	width: 100%;
	background: transparent;
	height: 42px;
	border: none;
	outline: none;
	padding-left: 20px;
	border-bottom: solid 2px #d9d9d9;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu .search_input::-webkit-input-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.15) !important;
}
.menu .search_input:-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.15) !important;
}
.menu .search_input::-moz-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.15) !important;
} 
.menu .search_input:-ms-input-placeholder
{ 
	font-size: 14px !important;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.15) !important;
}
.menu .search_input::input-placeholder
{
	font-size: 14px !important;
	font-weight: 500 !important;
	color: rgba(0,0,0,0.15) !important;
}
.menu .search_input:focus
{
	border-bottom: solid 2px #ff6600;
}
.header_search_button
{
	position: absolute;
	top: 0;
	right: 0;
	width: 40px;
	height: 100%;
	border: none;
	outline: none;
	cursor: pointer;
	background: transparent;
}
.header_search_button:focus
{
	outline: none;
}
.header_search_button i
{
	font-size: 16px;
	color: #d9d9d9;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.header_search_button:hover i
{
	color: #ff8a00;
}
.menu_extra
{
	position: absolute;
	right: 60px;
	bottom: 30px;
}
.menu_phone,
.menu_social
{
	font-size: 12px;
}
.menu_social
{
	margin-right: -6px;
	margin-top: 5px;
}
.menu_title
{
	text-transform: uppercase;
	margin-right: 10px;
}
.menu_social ul
{
	display: inline-block;
}
.menu_social ul li
{
	display: inline-block;
}
.menu_social ul li a i
{
	font-size: 12px;
	color: rgba(0,0,0,0.4);
	padding: 6px;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.menu_social ul li a i:hover
{
	color: #ff6600;
}

/*********************************
5. Home
*********************************/

.home
{
	width: 100%;
	height: 430px;
}
.home_container
{
	position: absolute;
	bottom: 92px;
	left: 0;
	width: 100%;
}
.home_content
{

}
.home_title
{
	font-size: 48px;
	font-weight: 600;
	line-height: 0.75;
	color: #FFFFFF;
}
.breadcrumbs
{
	margin-top: 14px;
}
.breadcrumbs ul li
{
	display: inline-block;
	position: relative;
	color: #ff8a00;
	font-size: 12px;
	font-weight: 600;
}
.breadcrumbs ul li a
{
	font-size: 12px;
	color: #FFFFFF;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.breadcrumbs ul li a:hover
{
	color: #ff8a00;
}
.breadcrumbs ul li:not(:last-child)::after
{
	display: inline-block;
	position: relative;
	content: '/';
	margin-left: 6px;
	font-size: 12px;
	font-weight: 600;
	color: #FFFFFF;
	line-height: 0.75;
}

/*********************************
6. About
*********************************/

.about
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 94px;
	padding-bottom: 111px;
}
.about_row:not(:last-child)
{
	margin-bottom: 99px;
}
.about_content
{
	top: 50%;
	-webkit-transform: translateY(-50%);
	-moz-transform: translateY(-50%);
	-ms-transform: translateY(-50%);
	-o-transform: translateY(-50%);
	transform: translateY(-50%);
}
.about_image
{
	width: 100%;
}
.about_image img
{
	max-width: 100%;
}
.about_title
{
	font-size: 36px;
	font-weight: 700;
	color: #44425a;
}
.about_text
{
	margin-top: 61px;
}

/*********************************
7. Teachers
*********************************/

.teachers
{
	width: 100%;
	background: #FFFFFF;
	padding-top: 85px;
	padding-bottom: 101px;
}
.teachers_title
{
	font-size: 36px;
	font-weight: 700;
	color: #44425a;
}
.teachers_row
{
	margin-top: 68px;
}
.teacher
{
	width: 100%;
	margin-bottom: 30px;
}
.teacher_image
{
	width: 100%;
}
.teacher_image img
{
	max-width: 100%;
}
.teacher_image::after
{
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	background: rgba(255,138,0,0.2);
	content: '';
	visibility: hidden;
	opacity: 0;
	-webkit-transition: all 500ms ease;
	-moz-transition: all 500ms ease;
	-ms-transition: all 500ms ease;
	-o-transition: all 500ms ease;
	transition: all 500ms ease;
}
.teacher:hover .teacher_image::after
{
	visibility: visible;
	opacity: 1;
}
.teacher_body
{
	padding-top: 43px;
	padding-left: 30px;
	padding-right: 30px;
	padding-bottom: 47px;
}
.teacher_title a
{
	font-size: 24px;
	font-weight: 600;
	color: #44425a;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.teacher_title a:hover
{
	color: #ff8a00;
}
.teacher_subtitle
{
	font-size: 14px;
	font-weight: 500;
	color: #6c6a74;
	margin-top: 1px;
}
.teacher_social
{
	margin-top: 24px;
}
.teacher_social ul li
{
	display: inline-block;
}
.teacher_social ul li:not(:last-child)
{
	margin-right: 24px;
}
.teacher_social ul li a i
{
	font-size: 17px;
	color: #ff6600;
}
.teachers_button
{
	margin-top: 18px;
}

/*********************************
8. Milestones
*********************************/

.milestones
{
	width: 100%;
	padding-top: 156px;
	padding-bottom: 161px;
}
.milestones_container
{
	margin-top: 0px;
}
.milestone
{
	width: 100%;
}
.milestone_icon
{
	display: inline-block;
	height: 81px;
	width: 81px;
}
.milestone_icon img
{
	max-width: 100%;
}
.milestone_counter
{
	font-size: 48px;
	font-weight: 500;
	color: #FFFFFF;
	line-height: 0.75;
	margin-top: 37px;
}
.milestone_text
{
	font-size: 18px;
	font-weight: 500;
	color: #FFFFFF;
	margin-top: 21px;
	line-height: 0.75;
}

/*********************************
9. Footer
*********************************/

.footer
{
	width: 100%;
	background: #f2f1f8;
	padding-top: 108px;
	padding-bottom: 90px;
}
.footer .logo_container
{
	position: absolute;
	top: -10px;
	left: 0;
}
.footer_about_text
{
	padding-top: 49px;
}
.footer_social
{
	margin-top: 22px;
}
.footer_social ul li
{
	display: inline-block;
}
.footer_social ul li:not(:last-child)
{
	margin-right: 24px;
}
.footer_social ul li a i
{
	font-size: 16px;
	color: #ff6600;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_social ul li a i:hover
{
	color: #ffae00;
}
.copyright
{
	font-size: 12px;
	font-weight: 500;
	color: #b3b3b3;
	margin-top: 70px;
}
.footer_title
{
	font-size: 18px;
	font-weight: 600;
	color: #44425a;
}
.footer_list
{
	margin-top: 26px;
}
.footer_list li:not(:last-child)
{
	margin-bottom: 15px;
}
.footer_list li a
{
	font-size: 14px;
	font-weight: 500;
	color: #6c6a74;
	-webkit-transition: all 200ms ease;
	-moz-transition: all 200ms ease;
	-ms-transition: all 200ms ease;
	-o-transition: all 200ms ease;
	transition: all 200ms ease;
}
.footer_list li a:hover
{
	color: #ff8a00;
}
.footer_contact_info
{
	padding-right: 70px;
	margin-top: 26px;
}
.footer_contact_item:not(:last-child)
{
	margin-bottom: 23px;
}
.footer_contact_title
{
	font-size: 14px;
	font-weight: 500;
	color: #ff6600;
}
.footer_contact_line
{
	font-size: 14px;
	font-weight: 500;
	color: #6c6a74;
	margin-top: 3px;
}

@charset "utf-8";
/* CSS Document */

/******************************

[Table of Contents]

1. 1600px
2. 1440px
3. 1280px
4. 1199px
5. 1024px
6. 991px
7. 959px
8. 880px
9. 768px
10. 767px
11. 539px
12. 479px
13. 400px

******************************/

/************
1. 1600px
************/

@media only screen and (max-width: 1600px)
{
	
}

/************
2. 1440px
************/

@media only screen and (max-width: 1440px)
{
	
}

/************
3. 1380px
************/

@media only screen and (max-width: 1380px)
{
	
}

/************
3. 1280px
************/

@media only screen and (max-width: 1280px)
{
	
}

/************
4. 1199px
************/

@media only screen and (max-width: 1199px)
{
	.footer_contact_info
	{
		padding-right: 0px;
	}
}

/************
4. 1100px
************/

@media only screen and (max-width: 1100px)
{
	
}

/************
5. 1024px
************/

@media only screen and (max-width: 1024px)
{
	
}

/************
6. 991px
************/

@media only screen and (max-width: 991px)
{
	.main_nav
	{
		display: none;
	}
	.hamburger
	{
		display: inline-block;
		margin-left: 25px;
		vertical-align: middle;
	}
	.top_bar_contact_list li > div
	{
		font-size: 10px;
	}
	.top_bar_contact_list li:first-child
	{
		margin-right: 32px !important;
	}
	.top_bar_contact_list li:not(:last-child)
	{
		margin-right: 23px !important;
	}
	.top_bar_login ul li a
	{
		font-size: 10px;
	}
	.about_image
	{
		margin-top: 40px;
	}
	.milestone_col:not(:last-child)
	{
		margin-bottom: 80px;
	}
	.footer_col:not(:last-child)
	{
		margin-bottom: 80px;
	}
}

/************
7. 959px
************/

@media only screen and (max-width: 959px)
{
	
}

/************
8. 880px
************/

@media only screen and (max-width: 880px)
{
	
}

/************
9. 768px
************/

@media only screen and (max-width: 768px)
{
	
}

/************
10. 767px
************/

@media only screen and (max-width: 767px)
{
	.top_bar
	{
		display: none;
	}
	.header.scrolled
	{
		top: 0px;
	}
	.search_button
	{
		display: none;
	}
	.home
	{
		height: 370px;
	}
	.home_title
	{
		font-size: 36px;
	}
}

/************
11. 575px
************/

@media only screen and (max-width: 575px)
{
	p
	{
		font-size: 13px;
	}
	h2
	{
		font-size: 20px;
	}
	.section_title h2
	{
		font-size: 20px;
	}
	.section_subtitle
	{
		font-size: 13px;
		margin-top: 47px;
	}
	.menu
	{
		width: 100%;
		right: -100%;
		padding-left: 30px;
		padding-right: 30px;
		padding-top: 80px;
	}
	.menu_nav ul li a
	{
		font-size: 14px;
	}
	.menu_close_container
	{
		right: 30px;
	}
	.menu .search
	{
		margin-bottom: 54px;
	}
	.menu .search_input
	{
		height: 32px;
	}
	.menu .search_input::-webkit-input-placeholder
	{
		font-size: 12px !important;
	}
	.menu .search_input:-moz-placeholder
	{
		font-size: 12px !important;
	}
	.menu .search_input::-moz-placeholder
	{
		font-size: 12px !important;
	} 
	.menu .search_input:-ms-input-placeholder
	{ 
		font-size: 12px !important;
	}
	.menu .search_input::input-placeholder
	{
		font-size: 12px !important;
	}
	.menu_extra
	{
		right: 30px;
		bottom: 15px;
	}
	.menu_phone, .menu_social
	{
		font-size: 9px;
	}
	.menu_social
	{
		margin-top: 2px;
	}
	.header_search_button i
	{
		font-size: 14px;
	}
	.button_arrow
	{
		height: 47px;
	}
	.header_content
	{
		height: 50px;
	}
	.header.scrolled .header_content
	{
		height: 50px;
	}
	.logo_img
	{
		width: 40px;
	}
	.logo_img img
	{
		max-width: 100%;
	}
	.logo_text
	{
		font-size: 12px;
		-webkit-transform: translateY(-1px);
		-moz-transform: translateY(-1px);
		-ms-transform: translateY(-1px);
		-o-transform: translateY(-1px);
		transform: translateY(-1px);
	}
	.home
	{
		height: 260px;
	}
	.home_container
	{
		bottom: 71px;
	}
	.home_title
	{
		font-size: 24px;
	}
	.breadcrumbs
	{
		margin-top: 10px;
	}
	.about_row:not(:last-child)
	{
		margin-bottom: 66px;
	}
	.about_title
	{
		font-size: 24px;
	}
	.about_text
	{
		margin-top: 33px;
	}
	.milestone_icon
	{
		width: 61px;
		height: 61px;
	}
	.milestone_counter
	{
		font-size: 30px;
	}
	.milestone_text
	{
		font-size: 14px;
	}
}

/************
11. 539px
************/

@media only screen and (max-width: 539px)
{
	
}

/************
12. 480px
************/

@media only screen and (max-width: 480px)
{
	.home_title
	{
		font-size: 24px;
	}
	.home_subtitle
	{
		font-size: 13px;
	}
}

/************
13. 479px
************/

@media only screen and (max-width: 479px)
{
	
}

/************
14. 400px
************/

@media only screen and (max-width: 400px)
{
	
}