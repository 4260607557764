.form-container {
    width: 100%;
	position: absolute;
	top: 15vh;
	background: #fff;
	padding: 20px;
	box-shadow: 0px 0px 10px 0px #000;
}


@media only screen and (max-width: 828px)
{
	.form-container{
	position: relative;
	top: 10vh;
	background: #fff;
	padding: 30px;
	border-radius: 10px;
	box-shadow: 0px 0px 10px 0px #000;
	}
}

