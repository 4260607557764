.navbar {
    background: #1f1e31;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .navbar-logo {
    color: #fff;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
  }
  
  .fa-firstdraft {
    margin-left: 0.5rem;
    font-size: 1.6rem;
  }
  
  .nav-menu {
    display: grid;
    grid-template-columns: repeat(5, auto);
    grid-gap: 10px;
    list-style: none;
    text-align: center;
    width: 70vw;
    justify-content: end;
    margin-right: 2rem;
  }

  
  .nav-item {
    display: flex;
    align-items: center;
    height: 80px;
  }
  
  .nav-links {
    color: #fff;
    text-decoration: none;
    padding: 0.5rem 1rem;
  }

  
  
  .fa-bars {
    color: #fff;
  }
  
  .nav-links-mobile {
    display: none;
  }
  
  .menu-icon {
    display: none;
  }
  
  @media screen and (max-width: 960px) {
    .NavbarItems {
      position: relative;
    }
  
    .nav-menu {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      left: -100%;
      opacity: 1;
      transition: all 0.5s ease;
    }
  
    .nav-menu.active {
      background: #242222;
      left: 0;
      opacity: 1;
      transition: all 0.5s ease;
      z-index: 1;
    }
  
    .nav-links {
      text-align: center;
      padding: 2rem;
      width: 100%;
      display: table;
    }
  
    .nav-links:hover {
      background-color: #1888ff;
      border-radius: 0;
    }
  
    .navbar-logo {
      display: none;
    }
  
    .menu-icon {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  
    .fa-times {
      color: #fff;
      font-size: 2rem;
    }
  
    .nav-links-mobile {
      display: block;
      text-align: center;
      padding: 1.5rem;
      margin: 2rem auto;
      border-radius: 4px;
      width: 80%;
      background: #1888ff;
      text-decoration: none;
      color: #fff;
      font-size: 1.5rem;
    }
  
    .nav-links-mobile:hover {
      background: #fff;
      color: #1888ff;
      transition: 250ms;
    }
  
    button {
      display: none;
    }
  }

  @charset "utf-8";
  /* CSS Document */
  
  /******************************
  
  [Table of Contents]
  
  1. Fonts
  2. Body and some general stuff
  3. Header
    3.1 Top Bar
    3.2 Header Content
    3.3 Logo
    3.4 Main Nav
    3.5 Hamburger
  4. Menu
  5. Home
  6. News
  7. Sidebar
  8. Footer
  
  
  ******************************/
  
  /***********
  1. Fonts
  ***********/
  
  @import url('https://fonts.googleapis.com/css?family=Montserrat:300,400,400i,500,600,700,800,900');
  
  /*********************************
  2. Body and some general stuff
  *********************************/
  
  *
  {
    margin: 0;
    padding: 0;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
  }
  body
  {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    font-weight: 500;
    background: #FFFFFF;
    color: #6c6a74;
  }
  div
  {
    display: block;
    position: relative;
    -webkit-box-sizing: border-box;
      -moz-box-sizing: border-box;
      box-sizing: border-box;
  }
  ul
  {
    list-style: none;
    margin-bottom: 0px;
  }
  p
  {
    font-family: 'Montserrat', sans-serif;
    font-size: 14px;
    line-height: 2.14;
    font-weight: 500;
    color: #6c6a74;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
  }
  p a
  {
    display: inline;
    position: relative;
    color: inherit;
    border-bottom: solid 1px #ffa07f;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  p:last-of-type
  {
    margin-bottom: 0;
  }
  a, a:hover, a:visited, a:active, a:link
  {
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
  }
  p a:active
  {
    position: relative;
    color: #FF6347;
  }
  p a:hover
  {
    color: #FFFFFF;
    background: #ffa07f;
  }
  p a:hover::after
  {
    opacity: 0.2;
  }
  ::selection
  {
    background: #ff8a00;
    color: #FFFFFF;
  }
  p::selection
  {
    
  }
  h1{font-size: 48px;}
  h2{font-size: 36px;}
  h3{font-size: 24px;}
  h4{font-size: 18px;}
  h5{font-size: 14px;}
  h1, h2, h3, h4, h5, h6
  {
    font-family: 'Montserrat', sans-serif;
    color: #44425a;
    -webkit-font-smoothing: antialiased;
    -webkit-text-shadow: rgba(0,0,0,.01) 0 0 1px;
    text-shadow: rgba(0,0,0,.01) 0 0 1px;
  }
  h1::selection, 
  h2::selection, 
  h3::selection, 
  h4::selection, 
  h5::selection, 
  h6::selection
  {
    
  }
  .form-control
  {
    color: #db5246;
  }
  section
  {
    display: block;
    position: relative;
    box-sizing: border-box;
  }
  .clear
  {
    clear: both;
  }
  .clearfix::before, .clearfix::after
  {
    content: "";
    display: table;
  }
  .clearfix::after
  {
    clear: both;
  }
  .clearfix
  {
    zoom: 1;
  }
  .float_left
  {
    float: left;
  }
  .float_right
  {
    float: right;
  }
  .trans_200
  {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .trans_300
  {
    -webkit-transition: all 300ms ease;
    -moz-transition: all 300ms ease;
    -ms-transition: all 300ms ease;
    -o-transition: all 300ms ease;
    transition: all 300ms ease;
  }
  .trans_400
  {
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }
  .trans_500
  {
    -webkit-transition: all 500ms ease;
    -moz-transition: all 500ms ease;
    -ms-transition: all 500ms ease;
    -o-transition: all 500ms ease;
    transition: all 500ms ease;
  }
  .fill_height
  {
    height: 100%;
  }
  .super_container
  {
    width: 100%;
    overflow: hidden;
  }
  .prlx_parent
  {
    overflow: hidden;
  }
  .prlx
  {
    height: 130% !important;
  }
  .parallax-window
  {
      min-height: 400px;
      background: transparent;
  }
  .parallax_background
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
  .nopadding
  {
    padding: 0px !important;
  }
  .button
  {
    display: inline-block;
    width: auto;
    height: 47px;
    background: #ff8a00;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .button a
  {
    display: block;
    position: relative;
    padding-left: 33px;
    padding-right: 77px;
    line-height: 47px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    text-transform: uppercase;
    white-space: nowrap;
  }
  .button_arrow
  {
    position: absolute;
    top: 0;
    right: 0;
    width: 44px;
    height: 100%;
    background: #ff6600;
    text-align: center;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .button_arrow i
  {
    font-size: 20px;
    line-height: 47px;
    color: #ffae00;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .button:hover
  {
    background: #ffae00;
  }
  .button:hover .button_arrow
  {
    background: #ff8a00;
  }
  .button:hover .button_arrow i
  {
    color: #ffae00;
  }
  .section_title h2
  {
    font-weight: 700;
  }
  .section_subtitle
  {
    font-size: 14px;
    color: #6c6a74;
    text-align: center;
    margin-top: 66px;
    line-height: 2.14;
  }
  
  /*********************************
  3. Header
  *********************************/
  
  .header
  {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    z-index: 100;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.05);
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .header.scrolled
  {
    top: -61px;
  }
  
  /*********************************
  3.1 Top Bar
  *********************************/
  
  .top_bar
  {
    width: 100%;
    background: #2c2b31;
  }
  .header.scrolled .top_bar
  {
  
  }
  .top_bar_container
  {
    width: 100%;
    height: 100%;
  }
  .top_bar_content
  {
    width: 100%;
    height: 61px;
  }
  .top_bar_contact_list li
  {
    display: inline-block;
  }
  .question
  {
    font-family: 'Montserrat', serif;
    font-size: 12px;
    font-weight: 600 !important;
    color: #918ea0;
    margin-left: 0px;
    text-transform: uppercase;
  }
  .top_bar_contact_list li > div
  {
    display: inline-block;
    font-size: 12px;
    font-weight: 500;
    color: #918ea0;
  }
  .top_bar_contact_list li > div
  {
    margin-left: 1px;
  }
  .top_bar_contact_list li:first-child
  {
    margin-right: 42px !important;
  }
  .top_bar_contact_list li:not(:last-child)
  {
    margin-right: 33px;
  }
  .top_bar_login
  {
    
  }
  .top_bar_login ul li
  {
    display: inline-block;
    position: relative;
  }
  .top_bar_login ul li a
  {
    font-size: 12px;
    text-transform: uppercase;
    font-weight: 600;
    color: #FFFFFF;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .top_bar_login ul li a:hover
  {
    color: #ff8a00;
  }
  .top_bar_login ul li:not(:last-child)::after
  {
    display: inline-block;
    margin-left: 5px;
    margin-right: 1px;
    content: '/';
    font-size: 12px;
    color: #FFFFFF;
    font-weight: 600;
  }
  
  /*********************************
  3.2 Header Content
  *********************************/
  
  .header_container
  {
    width: 100%;
    background: #FFFFFF;
  }
  .header_content
  {
    height: 90px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .header.scrolled .header_content
  {
    height: 70px;
  }
  
  /*********************************
  3.3 Logo
  *********************************/
  
  .logo,
  .logo_text
  {
    display: inline-block;
  }
  .logo
  {
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .logo_text
  {
    font-family: 'Montserrat', serif;
    font-size: 18px;
    font-weight: 700;
    line-height: 0.75;
    margin-left: 1px;
    color: #464646;
    text-transform: uppercase;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  
  /*********************************
  3.4 Main Nav
  *********************************/
  
  .main_nav_contaner
  {
  
  }
  .main_nav,
  .search_button
  {
    display: inline-block;
  }
  .main_nav li
  {
    display: inline-block;
    position: relative;
    height: 31px;
  }
  .main_nav li:not(:last-child)
  {
    margin-right: 13px;
  }
  .main_nav li a
  {
    display: block;
    font-size: 12px;
    font-weight: 600;
    line-height: 31px;
    background: #FFFFFF;
    color: #2c2b31;
    padding-left: 19px;
    padding-right: 19px;
    text-transform: uppercase;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .main_nav li a:hover,
  .main_nav li.active a
  {
    color: #FFFFFF;
    background: #ff8a00;
  }
  .main_nav li a:hover
  {
    background: #ff8a00;
  }
  .search_button
  {
    margin-left: 88px;
    cursor: pointer;
  }
  .search_button i
  {
    font-size: 15px;
    color: #181818;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .search_button:hover i
  {
    color: #ff8a00;
  }
  .header_search_form
  {
    display: block;
    position: relative;
    width: 40%;
  }
  .header_search_container
  {
    position: absolute;
    bottom: 0px;
    left: 0px;
    width: 100%;
    background: #2c2b31;
    z-index: -1;
    opacity: 0;
    -webkit-transition: all 400ms ease;
    -moz-transition: all 400ms ease;
    -ms-transition: all 400ms ease;
    -o-transition: all 400ms ease;
    transition: all 400ms ease;
  }
  .header_search_container.active
  {
    bottom: -73px;
    opacity: 1;
  }
  .header_search_content
  {
    width: 100%;
    height: 73px;
  }
  .search_input
  {
    width: 100%;
    height: 40px;
    border: none;
    outline: none;
    padding-left: 20px;
    background: transparent;
    border-bottom: solid 2px #ff8a00;
    color: #FFFFFF;
  }
  .header_search_button
  {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: none;
    outline: none;
    cursor: pointer;
  }
  
  /*********************************
  3.5 Hamburger
  *********************************/
  
  .hamburger_container
  {
  
  }
  .hamburger
  {
    display: none;
    cursor: pointer;
  }
  .hamburger i
  {
    font-size: 20px;
    color: #353535;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .hamburger:hover i
  {
    color: #ff8a00;
  }
  
  /*********************************
  4. Menu
  *********************************/
  
  .menu
  {
    position: fixed;
    top: 0;
    right: -400px;
    width: 400px;
    height: 100vh;
    background: #FFFFFF;
    z-index: 101;
    padding-right: 60px;
    padding-top: 87px;
    padding-left: 50px;
    box-shadow: 0px 5px 20px rgba(0,0,0,0.1);
  }
  .menu .logo a
  {
    color: #000000;
  }
  .menu.active
  {
    right: 0;
  }
  .menu_close_container
  {
    position: absolute;
    top: 30px;
    right: 60px;
    width: 18px;
    height: 18px;
    transform-origin: center center;
    -webkit-transform: rotate(45deg);
    -moz-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    -o-transform: rotate(45deg);
    transform: rotate(45deg);
    cursor: pointer;
  }
  .menu_close
  {
    width: 100%;
    height: 100%;
    transform-style: preserve-3D;
  }
  .menu_close div
  {
    width: 100%;
    height: 2px;
    background: #232323;
    top: 8px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .menu_close div:last-of-type
  {
    -webkit-transform: rotate(90deg) translateX(-2px);
    -moz-transform: rotate(90deg) translateX(-2px);
    -ms-transform: rotate(90deg) translateX(-2px);
    -o-transform: rotate(90deg) translateX(-2px);
    transform: rotate(90deg) translateX(-2px);
    transform-origin: center;
  }
  .menu_close:hover div
  {
    background: #937c6f;
  }
  .menu .logo
  {
    margin-bottom: 60px;
  }
  .menu_nav ul li
  {
    margin-bottom: 9px;
  }
  .menu_nav ul li a
  {
    font-size: 16px;
    color: #2c2b31;
    font-weight: 700;
    letter-spacing: 0.05em;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .menu_nav ul li a:hover
  {
    color: #ff6600;
  }
  .menu .search
  {
    width: 100%;
    margin-bottom: 67px;
  }
  .search
  {
    display: inline-block;
    width: 400px;
    -webkit-transform: translateY(2px);
    -moz-transform: translateY(2px);
    -ms-transform: translateY(2px);
    -o-transform: translateY(2px);
    transform: translateY(2px);
  }
  .menu .header_search_form
  {
    width: 100%;
  }
  .search form
  {
    position: relative;
  }
  .menu .search_input
  {
    width: 100%;
    background: transparent;
    height: 42px;
    border: none;
    outline: none;
    padding-left: 20px;
    border-bottom: solid 2px #d9d9d9;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .menu .search_input::-webkit-input-placeholder
  {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,0.15) !important;
  }
  .menu .search_input:-moz-placeholder
  {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,0.15) !important;
  }
  .menu .search_input::-moz-placeholder
  {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,0.15) !important;
  } 
  .menu .search_input:-ms-input-placeholder
  { 
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,0.15) !important;
  }
  .menu .search_input::input-placeholder
  {
    font-size: 14px !important;
    font-weight: 500 !important;
    color: rgba(0,0,0,0.15) !important;
  }
  .menu .search_input:focus
  {
    border-bottom: solid 2px #ff6600;
  }
  .header_search_button
  {
    position: absolute;
    top: 0;
    right: 0;
    width: 40px;
    height: 100%;
    border: none;
    outline: none;
    cursor: pointer;
    background: transparent;
  }
  .header_search_button:focus
  {
    outline: none;
  }
  .header_search_button i
  {
    font-size: 16px;
    color: #d9d9d9;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .header_search_button:hover i
  {
    color: #ff8a00;
  }
  .menu_extra
  {
    position: absolute;
    right: 60px;
    bottom: 30px;
  }
  .menu_phone,
  .menu_social
  {
    font-size: 12px;
  }
  .menu_social
  {
    margin-right: -6px;
    margin-top: 5px;
  }
  .menu_title
  {
    text-transform: uppercase;
    margin-right: 10px;
  }
  .menu_social ul
  {
    display: inline-block;
  }
  .menu_social ul li
  {
    display: inline-block;
  }
  .menu_social ul li a i
  {
    font-size: 12px;
    color: rgba(0,0,0,0.4);
    padding: 6px;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .menu_social ul li a i:hover
  {
    color: #ff6600;
  }
  
  /*********************************
  5. Home
  *********************************/
  
  .home
  {
    width: 100%;
    height: 430px;
  }
  .home_container
  {
    position: absolute;
    bottom: 92px;
    left: 0;
    width: 100%;
  }
  .home_content
  {
  
  }
  .home_title
  {
    font-size: 48px;
    font-weight: 600;
    line-height: 0.75;
    color: #FFFFFF;
  }
  .breadcrumbs
  {
    margin-top: 14px;
  }
  .breadcrumbs ul li
  {
    display: inline-block;
    position: relative;
    color: #ff8a00;
    font-size: 12px;
    font-weight: 600;
  }
  .breadcrumbs ul li a
  {
    font-size: 12px;
    color: #FFFFFF;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .breadcrumbs ul li a:hover
  {
    color: #ff8a00;
  }
  .breadcrumbs ul li:not(:last-child)::after
  {
    display: inline-block;
    position: relative;
    content: '/';
    margin-left: 6px;
    font-size: 12px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 0.75;
  }
  
  /*********************************
  6. News
  *********************************/
  
  .news
  {
    width: 100%;
    background: #FFFFFF;
    padding-top: 94px;
    padding-bottom: 94px;
  }
  .news_posts
  {
    width: 100%;
  }
  .news_post:not(:last-child)
  {
    margin-bottom: 30px;
  }
  .news_post_image
  {
    width: 100%;
  }
  .news_post_image img
  {
    max-width: 100%;
  }
  .news_post_body
  {
    padding-top: 42px;
    padding-bottom: 49px;
  }
  .news_post_date a
  {
    font-size: 12px;
    font-weight: 600;
    color: #ff5c00;
    line-height: 0.75;
  }
  .news_post_title
  {
    margin-top: 6px;
  }
  .news_post_title a
  {
    font-size: 30px;
    font-weight: 600;
    color: #44425a;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_post_title a:hover
  {
    color: #e6e5e4;
  }
  .news_post_meta
  {
    font-size: 14px;
    font-weight: 500;
  }
  .news_post_author,
  .news_post_author a
  {
    color: #6c6a74;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_post_author a:hover
  {
    color: #ff5c00;
  }
  .news_post_comments
  {
    margin-left: 16px;
  }
  .news_post_comments a
  {
    color: #ff8a00;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_post_comments a:hover
  {
    color: #ff5c00;
  }
  .news_post_tags
  {
    margin-left: 16px;
  }
  .news_post_tags ul
  {
    display: inline-block;
  }
  .news_post_tags ul li
  {
    display: inline-block;
  }
  .news_post_tags ul li a
  {
    color: #ff8a00;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_post_tags ul li a:hover
  {
    color: #ff5c00;
  }
  .news_post_text
  {
    margin-top: 38px;
  }
  .news_post_link
  {
    margin-top: 34px;
  }
  .news_post_link a
  {
    position: relative;
    font-size: 14px;
    font-weight: 700;
    color: #ff8a00;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_post_link a::after
  {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ff8a00;
    content: '';
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_post_link a:hover
  {
    color: #ff5c00;
  }
  .news_post_link a:hover::after
  {
    background: #ff5c00;
  }
  .news_pagination
  {
    margin-top: 27px;
  }
  .news_pagination ul li
  {
    display: inline-block;
  }
  .news_pagination ul li:not(:last-child)
  {
    margin-right: 9px;
  }
  .news_pagination ul li a
  {
    font-size: 14px;
    font-weight: 500;
    color: #6c6a74;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .news_pagination ul li a:hover,
  .news_pagination ul li.active a
  {
    color: #ff8a00;
  }
  
  /*********************************
  7. Sidebar
  *********************************/
  
  .sidebar
  {
    width: 100%;
  }
  .sidebar_search_form
  {
    width: 100%;
    border-bottom: solid 3px #ff8a00;
    padding-bottom: 11px;
  }
  .sidebar_search_input
  {
    width: 100%;
    border: none;
    outline: none;
    padding-left: 15px;
    padding-top: 4px;
    padding-bottom: 4px;
  }
  .sidebar_search_input::-webkit-input-placeholder
  {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-style: italic;
    color: #6c6a74 !important;
  }
  .sidebar_search_input:-moz-placeholder
  {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-style: italic;
    color: #6c6a74 !important;
  }
  .sidebar_search_input::-moz-placeholder
  {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-style: italic;
    color: #6c6a74 !important;
  } 
  .sidebar_search_input:-ms-input-placeholder
  { 
    font-size: 12px !important;
    font-weight: 400 !important;
    font-style: italic;
    color: #6c6a74 !important;
  }
  .sidebar_search_input::input-placeholder
  {
    font-size: 12px !important;
    font-weight: 400 !important;
    font-style: italic;
    color: #6c6a74 !important;
  }
  .sidebar_search_button
  {
    position: absolute;
    top: 3px;
    right: 15px;
    border: none;
    outline: none;
    background: transparent;
    cursor: pointer;
  }
  .sidebar_search_button:focus
  {
    outline: none;
    color: #ff8a00;
  }
  .sidebar_title
  {
    font-size: 20px;
    font-weight: 700;
    color: #44425a;
  }
  .sidebar_categories
  {
    margin-top: 75px;
  }
  .sidebar_links
  {
    padding-left: 43px;
    margin-top: 58px;
  }
  .sidebar_links ul li:not(:last-child)
  {
    margin-bottom: 15px;
  }
  .sidebar_links ul li a
  {
    font-size: 14px;
    font-weight: 500;
    color: #6c6a74;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .sidebar_links ul li a:hover
  {
    color: #ff5c00;
  }
  .sidebar_latest_posts
  {
    margin-top: 69px;
  }
  .latest_posts
  {
    width: 100%;
    margin-top: 63px;
  }
  .latest_post
  {
    width: 100%;
  }
  .latest_post:not(:last-child)
  {
    margin-bottom: 39px;
  }
  .latest_post_image
  {
    width: 72px;
    height: 72px;
  }
  .latest_post_image img
  {
    max-width: 100%;
  }
  .latest_post_body
  {
    padding-left: 25px;
  }
  .latest_post_date
  {
    font-size: 12px;
    font-weight: 600;
    color: #ff5c00;
    line-height: 0.75;
  }
  .latest_post_title
  {
    margin-top: 10px;
  }
  .latest_post_title a
  {
    font-size: 16px;
    font-weight: 600;
    color: #44425a;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .latest_post_title a:hover
  {
    color: #ff6600;
  }
  .latest_post_author
  {
    margin-top: 5px;
  }
  .latest_post_author,
  .latest_post_author a
  {
    font-size: 14px;
    font-weight: 500;
    color: #6c6a74;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .latest_post_author a:hover
  {
    color: #ff6600;
  }
  .sidebar_elearn
  {
    margin-top: 82px;
  }
  .elearn
  {
    width: 100%;
    height: 369px;
    padding-top: 81px;
    padding-left: 64px;
    padding-bottom: 84px;
    padding-right: 64px;
  }
  .elearn_background
  {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center center;
  }
  .elearn_content
  {
    width: 100%;
    height: 100%;
    background: rgba(0,0,0,0.83);
    padding-bottom: 28px;
    padding-left: 40px;
    padding-right: 40px;
    text-align: center;
  }
  .elearn_line
  {
    font-size: 24px;
    font-weight: 600;
    color: #FFFFFF;
  }
  .elearn_link
  {
    margin-top: 19px;
  }
  .elearn_link a
  {
    position: relative;
    font-size: 16px;
    font-weight: 600;
    color: #ff9324;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .elearn_link a::after
  {
    display: block;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background: #ff9324;
    content: '';
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .elearn_link a:hover
  {
    color: #e95a5a;
  }
  .elearn_link a:hover::after
  {
    background: #e95a5a;
  }
  .dcount
  {
    position: absolute;
    top: -35px;
    right: -35px;
  }
  .dcount_content
  {
    width: 100px;
    height: 100px;
    border-radius: 50%;
    background: #ff9324;
  }
  .dcount_value
  {
    font-size: 30px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 0.75;
  }
  .dcount_text
  {
    font-size: 18px;
    font-weight: 600;
    color: #FFFFFF;
    line-height: 0.75;
    margin-top: 10px;
  }
  
  /*********************************
  8. Footer
  *********************************/
  
  .footer
  {
    width: 100%;
    background: #f2f1f8;
    padding-top: 108px;
    padding-bottom: 90px;
  }
  .footer .logo_container
  {
    position: absolute;
    top: -10px;
    left: 0;
  }
  .footer_about_text
  {
    padding-top: 49px;
  }
  .footer_social
  {
    margin-top: 22px;
  }
  .footer_social ul li
  {
    display: inline-block;
  }
  .footer_social ul li:not(:last-child)
  {
    margin-right: 24px;
  }
  .footer_social ul li a i
  {
    font-size: 16px;
    color: #ff6600;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .footer_social ul li a i:hover
  {
    color: #ffae00;
  }
  .copyright
  {
    font-size: 12px;
    font-weight: 500;
    color: #b3b3b3;
    margin-top: 70px;
  }
  .footer_title
  {
    font-size: 18px;
    font-weight: 600;
    color: #44425a;
  }
  .footer_list
  {
    margin-top: 26px;
  }
  .footer_list li:not(:last-child)
  {
    margin-bottom: 15px;
  }
  .footer_list li a
  {
    font-size: 14px;
    font-weight: 500;
    color: #6c6a74;
    -webkit-transition: all 200ms ease;
    -moz-transition: all 200ms ease;
    -ms-transition: all 200ms ease;
    -o-transition: all 200ms ease;
    transition: all 200ms ease;
  }
  .footer_list li a:hover
  {
    color: #ff8a00;
  }
  .footer_contact_info
  {
    padding-right: 70px;
    margin-top: 26px;
  }
  .footer_contact_item:not(:last-child)
  {
    margin-bottom: 23px;
  }
  .footer_contact_title
  {
    font-size: 14px;
    font-weight: 500;
    color: #ff6600;
  }
  .footer_contact_line
  {
    font-size: 14px;
    font-weight: 500;
    color: #6c6a74;
    margin-top: 3px;
  }