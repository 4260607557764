.cp-container {
	position: absolute;
	top: 15vh;
	background: #fff;
	padding: 30px
}


@media only screen and (max-width: 828px)
{
	.cp-container{
	position: relative;
	top: 15vh;
	background: #fff;
	padding: 30px
	}
}