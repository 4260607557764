
:root {
    --primary: #1888ff;
  }
  
  .btn {
    padding: 8px 20px;
    border-radius: 4px;
    outline: none;
    border: none;
    font-size: 18px;
    color: rgb(255, 255, 255);
    cursor: pointer;
    background-color: var(--primary);
  }
  
  .btn:hover {
    padding: 6px 18px;
    transition: all 0.3s ease-out;
    background-color: transparent;
    color: rgba(213, 210, 231, 0.863);
    border-radius: 4px;
    border: 2px solid var(--primary);
    transition: all 0.3s ease-out;
  }

@media screen and (max-width: 960px) {
  .btn {
    display: none;
  }
}